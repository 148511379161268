export const offerteStatusVolgorde = [
	"NIEUW",
	"SCHRIJVEN",
	"NIETINGEDIEND",
	"UITSTAAND",
	"AFGEWEZEN",
	"INGETROKKEN",
	"SUCCES",
	"AFGEROND"
];

export const offerteStatussen = {
	NIEUW: "Nieuw (offerte-aanvraag)",
	SCHRIJVEN: "Offerte wordt geschreven",
	UITSTAAND: "Uitstaand (offerte ingediend)",
	AFGEWEZEN: "Afgewezen (na indiening)",
	INGETROKKEN: "Ingetrokken door opdrachtgever",
	NIETINGEDIEND: "Niet ingediend (door ons)",
	SUCCES: "Gegund (project)",
	AFGEROND: "Afgerond"
};

export const offerteStatusIcons = {
	NIEUW: "pi-star",
	SCHRIJVEN: "pi-pencil",
	UITSTAAND: "pi-clock",
	AFGEWEZEN: "pi-times-circle",
	INGETROKKEN: "pi-replay",
	NIETINGEDIEND: "pi-circle-off",
	SUCCES: "pi-thumbs-up",
	AFGEROND: "pi-check"
};

export const offerteStatusColors = {
	NIEUW: 80,
	SCHRIJVEN: 80,
	UITSTAAND: 300,
	AFGEWEZEN: 0,
	INGETROKKEN: 0,
	NIETINGEDIEND: 180,
	SUCCES: 120,
	AFGEROND: 140
};

export const consortiumRollen = {
	HOOFDAANNEMER: "Hoofdaannemer",
	ONDERAANNEMER: "Onderaannemer",
	COMBINANT: "Combinant"
};

export const consortiumRollenKort = {
	HOOFDAANNEMER: "Ha",
	ONDERAANNEMER: "Oa",
	COMBINANT: "Co"
};

// De indices in deze array verwijzen naar de cijfers in de tabel Uren, kolom Weekdag (en DATEPART(WEEKDAY, datum) geeft dezelfde indices in SQL Server)
export const weekDagen = ["zaterdag", "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag"];
export const weekDagenKort = ["za", "zo", "ma", "di", "wo", "do", "vr"];

export const countries: [string, string][] = [
	["AD", "Andorra"],
	["AL", "Albanië"],
	["AM", "Armenië"],
	["AT", "Oostenrijk"],
	["AU", "Australië"],
	["BA", "Bosnië en Herzegovina"],
	["BE", "België"],
	["BG", "Bulgarije"],
	["BY", "Belarus"],
	["CH", "Zwitserland"],
	["CY", "Cyprus"],
	["CZ", "Tsjechië"],
	["DE", "Duitsland"],
	["DK", "Denemarken"],
	["EE", "Estland"],
	["ES", "Spanje"],
	["FI", "Finland"],
	["FO", "Faeroe-eilanden"],
	["FR", "Frankrijk"],
	["GB", "Groot-Brittannië"],
	["GE", "Georgië"],
	["GI", "Gibraltar"],
	["GR", "Griekenland"],
	["HR", "Kroatië"],
	["HU", "Hongarije"],
	["IE", "Ierland"],
	["IS", "Ijsland"],
	["IT", "Italië"],
	["JP", "Japan"],
	["LI", "Liechtenstein"],
	["LT", "Litouwen"],
	["LU", "Luxemburg"],
	["LV", "Letland"],
	["MC", "Monaco"],
	["MK", "Macedonië"],
	["MT", "Malta"],
	["NL", "Nederland"],
	["NO", "Noorwegen"],
	["PL", "Polen"],
	["PT", "Portugal"],
	["RO", "Roemenië"],
	["RS", "Servië"],
	["RU", "Rusland"],
	["SE", "Zweden"],
	["SI", "Slovenië"],
	["SK", "Slowakije"],
	["SM", "San Marino"],
	["TR", "Turkije"],
	["UA", "Oekraïne"],
	["US", "Verenigde Staten"],
	["VA", "Vaticaanstad"],
	["ZA", "Zuid-Afrika"]
];

export function declaratieVergoedingPerKilometer(jaar: number) {
	// https://www.rijksoverheid.nl/actueel/nieuws/2023/12/20/belangrijkste-belastingwijzigingen-per-1-januari-2024
	if (jaar <= 2022) {
		return 0.19;
	} else if (jaar == 2023) {
		return 0.21;
	} else if (jaar >= 2024) {
		return 0.23;
	} else {
		throw new Error("reiskostenvergoeding niet gedefinieerd voor dit jaar");
	}
}

// Left-pad van een string met nullen tot een gegeven lengte
export function pad(str: any, length: number) {
	const s = str.toString();
	if (s.length < length) {
		return new Array(length - s.length + 1).join("0") + s;
	}
	return s;
}

export function parseGetal(str: any) {
	return parseFloat(("" + str).replace(/,/g, "."));
}

export function getWerkweek(date: Date) {
	let week = getISOWeek(date);
	const jaar = date.getFullYear();

	if (week <= 1 && date.getMonth() > 0) {
		return 53;
	}

	return week;
}

export function getVolgendeWerkWeek(jaar: number, week: number) {
	let volgendeWeek = week + 1;
	let volgendJaar = jaar;
	if (volgendeWeek > 53) {
		// Volgende jaar, maar check of dit met week 0 of week 1 begint
		volgendJaar++;
		const startVolgendJaar = new Date(volgendJaar, 0, 1, 10, 0, 0, 0);
		volgendeWeek = getISOWeek(startVolgendJaar);
	}
	return {jaar: volgendJaar, week: volgendeWeek};
}

export function getVorigeWerkWeek(jaar: number, week: number) {
	let vorigeWeek = week - 1;
	let vorigJaar = jaar;

	if (vorigeWeek === 0) {
		// Check of week 0 wel bestaat dit jaar
		const startDitJaar = new Date(vorigJaar, 0, 1, 10, 0, 0, 0);
		if (getISOWeek(startDitJaar) !== 0) {
			vorigeWeek = 53;
			vorigJaar--;
		}
	} else if (vorigeWeek < 0) {
		vorigeWeek = 53;
		vorigJaar--;
	}
	return {jaar: vorigJaar, week: vorigeWeek};
}

export function getISOWeek(date: Date) {
	const dowOffset = 1;
	var newYear = new Date(date.getFullYear(), 0, 1);
	var day = newYear.getDay() - dowOffset; //the day of week the year begins on
	day = day >= 0 ? day : day + 7;
	var daynum =
		Math.floor(
			(date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000
		) + 1;
	var weeknum;
	//if the year starts before the middle of a week
	if (day < 4) {
		weeknum = Math.floor((daynum + day - 1) / 7) + 1;
		if (weeknum > 52) {
			const nYear = new Date(date.getFullYear() + 1, 0, 1);
			let nday = nYear.getDay() - dowOffset;
			nday = nday >= 0 ? nday : nday + 7;
			/*if the next year starts before the middle of
 			  the week, it is week #1 of that year*/
			weeknum = nday < 4 ? 1 : 53;
		}
	} else {
		weeknum = Math.floor((daynum + day - 1) / 7);
	}
	return weeknum;
}

// https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript
export function maandagInWeek(year: number, week: number) {
	if (week < 1 || week > 53) {
		throw new RangeError("ISO 8601 weeks are numbered from 1 to 53");
	}

	const simple = new Date(year, 0, 1 + (week - 1) * 7);
	const dayOfWeek = simple.getDay();
	const isoWeekStart = simple;

	// Get the Monday past, and add a week if the day was
	// Friday, Saturday or Sunday.

	isoWeekStart.setDate(simple.getDate() - dayOfWeek + 1);
	if (dayOfWeek > 4) {
		isoWeekStart.setDate(isoWeekStart.getDate() + 7);
	}

	// The latest possible ISO week starts on December 28 of the current year.
	if (
		isoWeekStart.getFullYear() > year ||
		(isoWeekStart.getFullYear() == year && isoWeekStart.getMonth() == 11 && isoWeekStart.getDate() > 28)
	) {
		throw new RangeError(`${year} has no ISO week ${week}`);
	}

	return isoWeekStart;
}

export class Dag {
	readonly jaar: number;
	readonly maand: number;
	readonly dag: number;

	constructor(date: Date | string | {jaar: number; maand: number; dag: number}) {
		if (typeof date === "object" && date !== null && date instanceof Date) {
			this.jaar = date.getFullYear();
			this.maand = date.getMonth() + 1; // Weird, JavaScript
			this.dag = date.getDate();
		} else if (typeof date === "string") {
			const parts = date.toString().split("-");
			if (parts.length !== 3) throw new Error("invalid date string: " + date);
			this.jaar = parseInt(parts[0]!, 10);
			this.maand = parseInt(parts[1]!, 10);
			this.dag = parseInt(parts[2]!, 10);
		} else {
			const d = date as any;
			if (typeof d !== "object" || d === null || !("jaar" in d)) {
				throw new Error("invalid date: " + JSON.stringify(d));
			}
			this.jaar = d.jaar;
			this.maand = d.maand;
			this.dag = d.dag;
		}
	}

	get isToekomstig() {
		return this.startDagDate.getTime() > Dag.vandaag().startDagDate.getTime();
	}

	get startDagDate() {
		return new Date(this.jaar, this.maand - 1, this.dag, 0, 0, 0, 0);
	}

	get dateString() {
		return `${pad(this.jaar.toString(), 4)}-${pad(this.maand.toString(), 2)}-${pad(this.dag.toString(), 2)}`;
	}

	get ddmmyyyy() {
		return `${pad(this.dag.toString(), 2)}-${pad(this.maand.toString(), 2)}-${pad(this.jaar.toString(), 4)}`;
	}

	static vandaag() {
		const d = new Date();
		return new Dag({
			jaar: d.getFullYear(),
			maand: d.getMonth() + 1, // Weird, JavaScript
			dag: d.getDate()
		});
	}
}

export function groupBy<T>(arrayOfObjects: T[], field: keyof T) {
	return arrayOfObjects.reduce(function (rv: {[key: string | number | symbol]: T[]}, x: T) {
		const k = x[field] as any;
		(rv[k] = rv[k] || []).push(x);
		return rv;
	}, {});
}

export function keyBy<T>(arrayOfObjects: T[], field: keyof T) {
	return arrayOfObjects.reduce(function (rv: {[key: string | number | symbol]: T}, x: T) {
		const k = x[field] as any;
		if (k in rv) {
			throw new Error("key appears twice in list: " + k);
		}
		rv[k] = JSON.parse(JSON.stringify(x));
		delete rv[k]![field];
		return rv;
	}, {});
}

export function unique<T>(arrayOfObjects: T[]) {
	return [...new Set(arrayOfObjects)];
}

export function sorted<T extends Record<string, any>>(items: T[], field: string) {
	const xs = items.slice();
	xs.sort((a: Record<string, any>, b: Record<string, any>) => {
		const av: any = a[field];
		const bv: any = b[field];
		return av < bv ? -1 : av > bv ? 1 : 0;
	});
	return xs;
}

export function naamAfkorting(naam: string) {
	if (naam.length <= 3) return naam;

	return (
		naam.substring(0, 1) +
		naam
			.substring(1, 99)
			.replace(/[aeiou]/gi, "")
			.substring(0, 2)
	);
}

// Zie: https://docs.peppol.eu/poacc/billing/3.0/codelist/eas/
export const eFactuurAdresTypeSchemeID: Record<string, string> = {
	OIN: "0190",
	KVK: "0106",
	GLN: "0209"
};

// Zie: https://docs.peppol.eu/poacc/billing/3.0/codelist/eas/
export const eFactuurVAT: Record<string, string> = {
	BE: "9925",
	CH: "9927",
	DE: "9930",
	EE: "9931",
	NL: "9944"
};

export function schemeIDFor(eFactuurAdresType: string, eFactuurAdres: string) {
	if (!eFactuurAdres || eFactuurAdres.length === 0 || eFactuurAdres.match(/[^0-9a-zA-Z]/g)) {
		return null;
	}

	if (eFactuurAdresType === "VAT") {
		return eFactuurVAT[eFactuurAdres.substring(0, 2).toUpperCase()] || null;
	}

	return eFactuurAdresTypeSchemeID[eFactuurAdresType] || null;
}

export const defaultLeverancierData = {
	bedrijfsNaam: "Dialogic Innovatie & Interactie",
	kvkNummer: 55415288,
	contactEmail: "secretariaat@dialogic.nl",
	contactTelefoon: "+31302150560",
	adres: "Hooghiemstraplein 33",
	postCode: "3514AX",
	plaats: "Utrecht",
	land: "NL",
	btwNummer: "NL851698529B01"
};

export const defaultPaymentData = {
	iban: "NL04INGB0660029464",
	bic: "INGBNL2A",
	valuta: "EUR"
};

export function selectElement(el: HTMLElement) {
	if (document.createRange && window.getSelection) {
		const sel = window.getSelection();
		if (sel) {
			sel.removeAllRanges();
			const range = document.createRange();
			try {
				range.selectNodeContents(el);
			} catch (e) {
				range.selectNode(el);
			}
			sel.addRange(range);
		}
	}
}

export function deselectAll() {
	const sel = window.getSelection();
	if (sel) {
		sel.removeAllRanges();
	}
}

export function verticaleTab(evt: KeyboardEvent) {
	// Zoek element waarin verticale tab werd gevraagd
	const target = evt.target;
	if (target === null) return;
	const targetTD = (evt.target as HTMLElement).parentNode;
	if (!targetTD) return;

	// Rij van huidige cel
	const targetTR = targetTD.parentNode as HTMLElement;
	if (!targetTR) return;

	// Index van huidige cel in rij
	const targetIndex = ([] as Array<Node>).indexOf.call(targetTR.children, targetTD);
	if (targetIndex < 0) return;

	// Volgende rij
	const nextTR = evt.shiftKey ? targetTR.previousElementSibling : targetTR.nextElementSibling;
	if (!nextTR) return;

	// Cel in volgende rij met zelfde index
	const nextTD = nextTR.children[targetIndex];
	if (!nextTD) return;

	// Input in die cel
	const nextInput = nextTD.firstElementChild as HTMLElement;
	if (!nextInput) return;
	nextInput.focus();
}

export function defaultParameter<T>(standaardWaarde: () => T) {
	const v: Ref<T | null> = ref(null);
	return computed({
		get() {
			if (v.value === null) {
				return standaardWaarde();
			}
			return v.value;
		},
		set(nv: T) {
			v.value = nv;
		}
	});
}

export function lerp(a: number, b: number, x: number) {
	x = Math.max(1, Math.min(0, x));
	return a * x + b * (1 - x);
}

export function lerpColor(a: [number, number, number], b: [number, number, number], x: number) {
	return [lerp(a[0], b[0], x), lerp(a[1], b[1], x), lerp(a[2], b[2], x)];
}

export interface LinearModel {
	slope: number;
	intercept: number;
	r2: number;
}

export function linearRegression(y: number[], x: number[]): LinearModel {
	var lr = {};
	var n = y.length;
	var sum_x = 0;
	var sum_y = 0;
	var sum_xy = 0;
	var sum_xx = 0;
	var sum_yy = 0;

	for (var i = 0; i < y.length; i++) {
		sum_x += x[i]!;
		sum_y += y[i]!;
		sum_xy += x[i]! * y[i]!;
		sum_xx += x[i]! * x[i]!;
		sum_yy += y[i]! * y[i]!;
	}

	const slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
	return {
		slope,
		intercept: (sum_y - slope * sum_x) / n,
		r2: Math.pow(
			(n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
			2
		)
	};
}

export interface DateSeries {
	data: {x: Date; y: number}[];
}

export interface LinearDateSeries extends DateSeries {
	dashStyle: string;
	marker: any;
}

export function linearTrend(ys: number[], xs: number[]) {
	const model = linearRegression(ys, xs);
	const minX = xs.reduce((a: number | null, x: number) => (a === null ? x : Math.min(a, x)), null) || 0;
	const maxX = xs.reduce((a: number | null, x: number) => (a === null ? x : Math.max(a, x)), null) || 0;
	const minXPred = model.intercept + model.slope * minX;
	const maxXPred = model.intercept + model.slope * maxX;

	return {
		marker: {enabled: false},
		dashStyle: "dash",
		data: [
			{x: new Date(minX), y: minXPred},
			{x: new Date(maxX), y: maxXPred}
		]
	};
}

export function linearTrendDate(series: DateSeries): LinearDateSeries {
	const ys = series.data.map((d) => d.y);
	const xs = series.data.map((d) => d.x.getTime());
	const model = linearRegression(ys, xs);

	const minX = xs.reduce((a: number | null, x: number) => (a === null ? x : Math.min(a, x)), null) || 0;
	const maxX = xs.reduce((a: number | null, x: number) => (a === null ? x : Math.max(a, x)), null) || 0;
	const minXPred = model.intercept + model.slope * minX;
	const maxXPred = model.intercept + model.slope * maxX;

	return {
		marker: {enabled: false},
		dashStyle: "dash",
		data: [
			{x: new Date(minX), y: minXPred},
			{x: new Date(maxX), y: maxXPred}
		]
	};
}
